import { createSelector } from 'reselect'

const getProjectsData = state => state.projectsData;

export const getProjectsList = createSelector(
    getProjectsData,
    projectsData => {
        return projectsData.projectsList;
    }
);
