import React from 'react';
import {connect} from 'react-redux';

import loadingPageView from '../../hoc/loadingPageView.js';
import {ContactItem, ContactWrapper, MapWrapper} from "./styled-components";

import Phone from "../../assets/images/contact/1-phone.svg";
import Email from "../../assets/images/contact/2-mail.svg";
import Facebook from "../../assets/images/contact/3-facebook.svg";
import Instagram from "../../assets/images/contact/4-instagram.svg";

class Contact extends React.Component {
  render() {
    return (
      <ContactWrapper>
        <h2 className="Contact__title">Kontakt</h2>
        <h4 className="Contact__subTitle">Skontaktuj się z nami, a wspólnie stworzymy dom twoich marzeń</h4>
        <div className="Contact__contactsInfo">
          <a href="mailto:biuro@kkstudio.com.pl">
            <ContactItem>
              <i><img src={Email} alt="Email Icon"/></i>
              <h4>EMAIL</h4>
              <span>biuro@kkstudio.com.pl</span>
            </ContactItem>
          </a>
          <a href="tel:+48510687367">
            <ContactItem>
              <i><img src={Phone} alt="Email Icon"/></i>
              <h4>TELEFON</h4>
              <span>+48 510 687 367</span>
            </ContactItem>
          </a>
          <a href="https://www.instagram.com/kk_studio_projektowe/" target="_blank" rel="noopener noreferrer">
            <ContactItem>
              <i><img src={Instagram} alt="Email Icon"/></i>
              <h4>INSTAGRAM</h4>
              <span>Obserwuj nas</span>
            </ContactItem>
          </a>
          <a href="https://www.facebook.com/KKstudioprojektowe/" target="_blank" rel="noopener noreferrer">
            <ContactItem>
              <i><img src={Facebook} alt="Email Icon"/></i>
              <h4>FACEBOOK</h4>
              <span>Polub nas</span>
            </ContactItem>
          </a>
        </div>
        <MapWrapper>
          <h2 className="Map__title">Znajdz Nas</h2>
          <iframe
              title="Google Map"
              width="100%"
              height="100%"
              frameBorder="0"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCA7prCYd0iAZb00i5eMTvZ3SfmOUeintk&q=KK+Studio,15-001+Białystok" allowFullScreen>
          </iframe>
        </MapWrapper>
      </ContactWrapper>
    );
  }
}

Contact.propTypes = {};

const mapStateToProps = state => {
  return {}
};

const mapDispatchToProps = {};

export default loadingPageView(connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact));