import styled from 'styled-components/macro';

export const AboutUsWrapper = styled.section`
  margin: 160px auto 100px;
  padding: 10px 20px;
  max-width: 1600px;

  @media (min-width: 768px) {
    margin-top: 180px;
  }

  @media (min-width: 1024px) {
    margin-top: 200px;
  }
  
  article {
    max-width: 1024px;
    margin: 0 auto 40px;
  }
  .AboutUs__title {
    text-align: center;
    margin: 0 0 30px;
    font-weight: bold;
    color: #58c0cc;
    @media (min-width: 1024px) {
      font-size: 37px;
    }
  }
  .AboutUs__photoWrapper {
    width: 250px;
    height: 250px;
    margin: 0 auto;
    display: block;
    border-radius: 50%;
    overflow: hidden;
    @media (min-width: 768px) {
      display: inline-block;
      margin-top: 60px;
      margin-right: 50px;
    }
    .AboutUs__KKPhoto {
      width: 100%;
      height: 100%;
    }
  }
  .AboutUs__text {
    text-align: justify;
    font-size: 14px;
    line-height: 170%;
    @media (min-width: 768px) {
      width: 400px;
      display: inline-block;
      vertical-align: top;
      font-size: 16px;
    }
    @media (min-width: 1024px) {
      font-size: 20px;
      width: 680px;
    }
  }
  .AboutUs__statsWrapper {
    text-align: center;
    .statsWrapper__stats {
      margin: 0 auto 40px;
      @media (min-width: 768px) {
        display: inline-block;
        vertical-align: top;
      }
      @media (min-width: 1024px) {
        margin: 0 25px 40px;
        transition: transform 300ms linear;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .AboutUs__commentsWrapper {
    .commentsWrapper__comment {
      margin-bottom: 35px;
      .comment__text {
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 10px;
      }

      .comment__author {
        margin: 10px;
        font-weight: bold;
      }
    }
    .commentsWrapper__comment--right {
      text-align: right;
    }
  }
`;