import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'normalize.css';
import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux';
import configureStore from './configureStore'

import App from './App.js';

const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);
