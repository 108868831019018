import styled from 'styled-components/macro';

export const ContactWrapper = styled.section`
  margin: 160px auto 100px;
  padding: 10px 20px;
  max-width: 1600px;

  @media (min-width: 768px) {
    margin-top: 180px;
  }

  @media (min-width: 1024px) {
    margin-top: 200px;
  }
  
  @media (min-width: 768px) {
    .Contact__contactsInfo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }
  }

  a {
    text-decoration: none;
  }
  .Contact__title {
    text-align: center;
    margin: 0 0 15px;
    font-weight: bold;
    color: #58c0cc;
    @media (min-width: 1024px) {
      font-size: 37px;
    }
  }
  .Contact__subTitle {
    text-align: center;
    margin: 0 0 30px;
    font-weight: normal;
    @media (min-width: 1024px) {
      font-size: 20px;
    }
  }
`;

export const ContactItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 35px;
  cursor: pointer;
  &:hover {
    i {
      background-color: rgba(88, 192, 204, 1);
    }
  }
  @media (min-width: 768px) {
    margin: 35px 10px;
  }
  @media (min-width: 1024px) {
    margin: 35px 20px;
  }
  i {
    display: flex;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 6px solid #58c0cc;
    align-items: center;
    justify-content: center;
    background-color: rgba(88, 192, 204, 0);
    transition: background-color linear 200ms;
    img {
      display: block;
      width: 75px;
      height: 75px;
    }
  }
  h4 {
    margin-bottom: 0;
    color: #58c0cc;
    font-weight: bold;
    text-align: center;
  }
  span {
    color: black;
  }
`;

export const MapWrapper = styled.section`
  width: 100%;
  height: 500px;
  margin: 50px 0 70px;
  padding-bottom: 70px;
  .Map__title {
    text-align: center;
    margin: 0 0 30px;
    font-weight: bold;
    color: #58c0cc;
    @media (min-width: 1024px) {
      font-size: 37px;
    }
  }
`;