import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";

import { getProjectsList } from '../../selectors/projectsSelectors';
import loadingPageView from '../../hoc/loadingPageView.js';
import ProjectsList from '../../components/projectsListComponent';
import ProjectDetail from '../ProjectDetail/ProjectDetail';

import { ProjectsWrapper } from "./styled-components";

class Projects extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
        showingProjectName: null,
        showingDetail: false
    };
  };
  
  componentDidMount = () => {
    const { history, match } = this.props;
    const { name } = match.params;

    this.unlisten = history.listen(this.showProject);
    if (name) {
        this.setState({ }, () =>
            history.replace(history.location.pathname, {
                name: name
            })
        );
    }
  };

  componentWillUnmount() {
    this.unlisten();
  }

  getCurrentProjectName = (location = this.props.history.location) =>
    location && location.state && location.state.name;

  showProject = location => {
    const name = this.getCurrentProjectName(location);
    
    if (name) {
        this.setState({
            showingDetail: true,
            showingProjectName: name
        });
    } else {
        this.setState({
            showingDetail: false,
            showingProjectName: null,
            showedPopupFromRefresh: false
        });
    }
  };
  
  closeProject = () => {
    if (this.props.history.location.pathname !== this.props.backUrl) {
        this.props.history.push(this.props.backUrl);
    }
  };

  onClickProject = projectName => {
    const href = `${this.props.backUrl}/${projectName}`;
    this.props.history.push(href, { name: projectName });
  };

  render() {
    const { state, props } = this;

    return (
      <ProjectsWrapper>
          <ProjectsList 
            list={props.projectsList}
            onClickProject={this.onClickProject}
            showingProjectName={state.showingProjectName}
          />
          <ProjectDetail
            isShowingDetail={state.showingDetail}
            showingProjectName={state.showingProjectName}
            closeModal={this.closeProject}
          />
      </ProjectsWrapper>
    );
  };
}

const { array } = PropTypes;

Projects.propTypes = {
  projectsList: array.isRequired
};

export default loadingPageView(connect(
  state => ({
    projectsList: getProjectsList(state),
  })
)(withRouter(Projects)));