import React from "react";
import {NavLink} from 'react-router-dom';

import {HeaderMenuWrapper, LogoImage, MenuBar, HamburgerButton, NavMenu} from "./styled-components";
import logo from '../../assets/images/kkstudio.png';

class HeaderMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
      shortMenu: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if(!this.checkModal()) {
      if (window.pageYOffset > 50 && !this.state.shortMenu) {
        this.setState({shortMenu: true})
      } else if (window.pageYOffset < 50 && this.state.shortMenu) {
        this.setState({shortMenu: false})
      }
    }
  };

  toggleMenu = () => {
    this.setState((state) => ({isMenuOpen: !state.isMenuOpen}));
  };

  closeMenu = () => {
    this.setState((state) => ({isMenuOpen: false}));
  };

  checkModal = () => {
    const body = document.querySelectorAll('body')[0];
    return body ? body.classList.contains('modal-opened') : false;
  }

  render() {
    return (
      <HeaderMenuWrapper shortMenu={this.state.shortMenu}>
        <LogoImage shortMenu={this.state.shortMenu}>
          <NavLink to="/">
            <img src={logo} alt="Logo firmy KK Studio"/>
          </NavLink>
        </LogoImage>
        <MenuBar shortMenu={this.state.shortMenu}>
          <div className="menu-bar__wrapper">
            <h1 className="wrapper__menu-title">KK STUDIO PROJEKTOWANIE WNĘTRZ</h1>
            <HamburgerButton onClick={this.toggleMenu} isMenuOpen={this.state.isMenuOpen}>
              <span/>
              <span/>
              <span/>
            </HamburgerButton>
            <NavMenu isMenuOpen={this.state.isMenuOpen} shortMenu={this.state.shortMenu}>
              <nav>
                <li onClick={this.closeMenu}><NavLink to="/o-nas">O nas</NavLink></li>
                <li onClick={this.closeMenu}><NavLink to="/oferta">Oferta</NavLink></li>
                <li onClick={this.closeMenu}><NavLink to="/projekty">Projekty</NavLink></li>
                <li onClick={this.closeMenu}><NavLink to="/kontakt">Kontakt</NavLink></li>
              </nav>
            </NavMenu>
          </div>
        </MenuBar>
      </HeaderMenuWrapper>
    );
  }
}

export default HeaderMenu;