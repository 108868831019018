import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Item, EpmptyItem, ItemWrapper, HoverBox, Content } from "./styled-components";

const ListItem = props => {
    const { item } = props;
    const onClick = () => props.onClick(item.name);

    return item ? (
        <Item onClick={onClick}>
            <ItemWrapper className='listItem'>
                <img className='image' src={item.thumbnailImage} alt='thumbnail' />
                <HoverBox className={classNames({ 'active': props.activeName === item.name })}>
                    <Content>
                        <h3 className='title'>{item.title}</h3>
                        <div className='divider' />
                        {item.flatArea && <p className='area'>{`${item.flatArea} m`}<sup>2</sup></p>}
                    </Content>
                </HoverBox>  
            </ItemWrapper>
        </Item>    
    ) : (
        <EpmptyItem />
    );
};

const { object, func, string } = PropTypes;

ListItem.propTypes = {
    item: object,
    onClick: func.isRequired,
    activeName: string
};

export default ListItem;