import React from "react";
import styled, { keyframes } from 'styled-components/macro';

import logo from '../../assets/images/kkstudio.png';

const pulse = keyframes`
  0 { opacity: 1; }
  50% { opacity: 0.3; }
  100% { opacity: 1; }
`;

const LoadingIconWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
`;
const LoadingIcon = styled.img`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 155px;
  padding: 5px;
  background-color: white;
  box-shadow: 0 0 30px 30px white;
  animation: ${pulse} 2s linear infinite;
`;

export default class LoadingPageView extends React.Component {
  render() {
    return (
      <LoadingIconWrapper>
        <LoadingIcon src={logo} />
      </LoadingIconWrapper>
    );
  }
}