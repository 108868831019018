import React from 'react';
import {connect} from 'react-redux';
import loadingPageView from "../../hoc/loadingPageView";
import {OfferSection, OfferWrapper} from "./styled-components";

import meetingImage from "../../assets/images/offer/meetingImage.jpeg";
import signing from "../../assets/images/offer/signing.jpeg";
import conceptualDesign from "../../assets/images/offer/conceptualDesign.jpeg";
import executiveProject from "../../assets/images/offer/executiveProject.jpeg";
import constructionSupervision from "../../assets/images/offer/constructionSupervision.jpeg";
import onlineProject from "../../assets/images/offer/onlineProject.jpeg";

class Offer extends React.Component {
  render() {
    return (
      <OfferWrapper>
        <h2 className="Offer__title">Oferta</h2>
        <OfferSection>
          <h4>Spotkanie wstępne</h4>
          <div className="Offer__circleClip">
            <img src={meetingImage} alt="Meeting"/>
          </div>
          <p>Na początku organizujemy spotkanie w celu poznania Państwa oczekiwań co do projektu, zainteresowań,
            upodobań. Do przygotowania szczegółowej oferty wykonujemy inwentaryzację projektowanych pomieszczeń oraz
            dokładnie oglądamy przestrzeń.</p>
        </OfferSection>
        <OfferSection>
          <h4>Umowa</h4>
          <div className="Offer__circleClip">
            <img src={signing} alt="Signing"/>
          </div>
          <p>Po zaakceptowaniu oferty podpisujemy z Państwem umowę zawierającą ustalenia co do szczegółowości projektu
            oraz płatności i terminów wykonania prac projektowych. Następnie przeprowadzamy szczegółowy wywiad na
            temat Państwa zainteresowań, stylu życia, upodobań.</p>
        </OfferSection>
        <OfferSection>
          <h4>Projekt koncepcyjny</h4>
          <div className="Offer__circleClip">
            <img src={conceptualDesign} alt="Conceptual Design"/>
          </div>
          <p>Wykonujemy projekt koncepcyjno-funkcjonalny na podstawie inwentaryzacji lub podkładów pdf/dwg.
            Proponujemy do 3 układów funkcjonalnych z możliwością kompilacji do jednej, ostatecznej oraz
            przygotowujemy ostateczną wersję na konkretnych gabarytach mebli.</p>
        </OfferSection>
        <OfferSection>
          <h4>Projekt wykonawczy</h4>
          <div className="Offer__circleClip">
            <img src={executiveProject} alt="Executive Project"/>
          </div>
          <p>Projekt wykonawczy obejmuje szczegółowy rzut z układem funkcjonalnym, na wybranych gabarytach mebli.
            Następnie wykonujemy rzuty z rozmieszczeniem punktów elektrycznych, hydraulicznych oraz oświetlenia, układ
            posadzek, sufitów, zmian budowlanych. W zależności od szczegółowości projektu wykonujemy rysunki zabudów
            meblowych dla stolarza. Przygotowujemy prezentację z propozycjami kolorystycznymi, wyborami materiałowymi,
            wyposażenia oraz proste szkicowe 3d - 1-2 ujęcia na każde z projektowanych pomieszczeń. Na życzenie
            klienta przygotowujemy kosztorysy wyposażenia i materiałów wykorzystanych w projekcie oraz indywidualne
            szacunkowe oferty od producentów. Dodatkowo, istnieje możliwość wykonania wizualizacji.</p>
        </OfferSection>
        <OfferSection>
          <h4>Nadzór autorski</h4>
          <div className="Offer__circleClip">
            <img src={constructionSupervision} alt="Construction Supervision"/>
          </div>
          <p>Zazwyczaj, podczas wykonywania projektu, oferujemy kilka (wcześniej ustalonych) spotkań na
            budowie oraz wizytę w sklepach. Przy bardziej szczegółowym projekcie - całych mieszkaniach,
            domach, biurach, istnieje możliwość indywidualnie wycenionych nadzorów autorskich nad
            zgodnością wykończenia wg projektu oraz spotkań z wykonawcami. Każde dodatkowe zmiany w
            projekcie po zakończeniu prac projektowych wyceniane są indywidualnie.</p>
        </OfferSection>
        <OfferSection>
          <h4>Projekt On-line</h4>
          <div className="Offer__circleClip">
            <img src={onlineProject} alt="OnlineProject"/>
          </div>
          <p>Istnieje możliwość wykonania projektu on-line. Kontakt z klientem odbywa się drogą mailową, projekt
            opracowany zostanie na podstawie pomiaru sporządzonego przez klienta według dokładnych wskazówek
            architekta.</p>
        </OfferSection>
      </OfferWrapper>
    )
  }
}

Offer.propTypes = {};

const mapStateToProps = state => {
  return {}
};

const mapDispatchToProps = {};

export default loadingPageView(connect(
  mapStateToProps,
  mapDispatchToProps
)(Offer));