import styled from 'styled-components/macro';

export const OfferWrapper = styled.section`
  max-width: 1024px;
  padding: 10px 20px;
  margin: 160px auto 100px;

  @media (min-width: 768px) {
    margin-top: 180px;
  }

  @media (min-width: 1024px) {
    margin-top: 200px;
    font-size: 37px;
  }
  
  .Offer__title {
    text-align: center;
    margin: 0 0 30px;
    font-weight: bold;
    color: #58c0cc;
  }
`;

export const OfferSection = styled.div`
  clear: both;
  @media (min-width: 768px) {
    margin-bottom: 30px;
    &:nth-child(odd) {
      .Offer__circleClip {
        float: right;
      }
      h4 {
        text-align: right;
      }
    }
    &:nth-child(even) {
      p {
        float: right;
      }
      h4 {
        text-align: left;
      }
    }
    &:hover {
      .Offer__circleClip {
        transform: scale(1.10);
      }
    }
  }
  @media (min-width: 1024px) {
    min-height: 330px;
  }
  h4 {
    text-align: center;
    margin: 0 0 20px;
    font-weight: bold;
    color: #58c0cc;
    font-size: 20px;
    @media (min-width: 768px) {
      font-size: 24px;
      margin-bottom: 30px;
    }
    @media (min-width: 1024px) {
      font-size: 37px;
    }
  }
  .Offer__circleClip {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
    transition: transform 300ms linear;
    @media (min-width: 768px) {
      display: inline-block;
    }
    @media (min-width: 1024px) {
      width: 250px;
      height: 250px;
    }
    img {
      width: auto;
      height: 100%;
    }
  }
  p {
    text-align: justify;
    font-size: 14px;
    line-height: 170%;
    @media (min-width: 768px) {
      font-size: 16px;
      display: inline-block;
      width: 520px;
      margin-top: 0;
    }
    @media (min-width: 1024px) {
      font-size: 20px;
      width: 670px;
    }
  }
`;