import wilnoMieszkanie1 from '../assets/images/gallery/wilnoMieszkanie/WM1.jpg';
import wilnoMieszkanie2 from '../assets/images/gallery/wilnoMieszkanie/WM2.jpg';
import wilnoMieszkanie3 from '../assets/images/gallery/wilnoMieszkanie/WM3.jpg';
import wilnoMieszkanie4 from '../assets/images/gallery/wilnoMieszkanie/WM4.jpg';
import wilnoMieszkanie5 from '../assets/images/gallery/wilnoMieszkanie/WM5.jpg';
import wilnoMieszkanie6 from '../assets/images/gallery/wilnoMieszkanie/WM6.jpg';
import wilnoMieszkanie7 from '../assets/images/gallery/wilnoMieszkanie/WM7.jpg';
import wilnoMieszkanie8 from '../assets/images/gallery/wilnoMieszkanie/WM8.jpg';
import wilnoThumbnail from '../assets/images/gallery/wilnoMieszkanie/WMthumbnail.jpg';

import serockDom1 from '../assets/images/gallery/serockDom/SD1.jpg';
import serockDom2 from '../assets/images/gallery/serockDom/SD2.jpg';
import serockDom3 from '../assets/images/gallery/serockDom/SD3.jpg';
import serockDom4 from '../assets/images/gallery/serockDom/SD4.jpg';
import serockDom5 from '../assets/images/gallery/serockDom/SD5.jpg';
import serockDom6 from '../assets/images/gallery/serockDom/SD6.jpg';
import serockDom7 from '../assets/images/gallery/serockDom/SD7.jpg';
import serockDom8 from '../assets/images/gallery/serockDom/SD8.jpg';
import serockDom9 from '../assets/images/gallery/serockDom/SD9.jpg';
import serockDom10 from '../assets/images/gallery/serockDom/SD10.jpg';
import serockDom11 from '../assets/images/gallery/serockDom/SD11.jpg';
import serockThumbnail from '../assets/images/gallery/serockDom/SDthumbnail.jpg';

import koczargiStareDom1 from '../assets/images/gallery/koczargiStareDom/KSD1.jpg';
import koczargiStareDom2 from '../assets/images/gallery/koczargiStareDom/KSD2.jpg';
import koczargiStareDom3 from '../assets/images/gallery/koczargiStareDom/KSD3.jpg';
import koczargiStareDom4 from '../assets/images/gallery/koczargiStareDom/KSD4.jpg';
import koczargiStareDom5 from '../assets/images/gallery/koczargiStareDom/KSD5.jpg';
import koczargiStareDom6 from '../assets/images/gallery/koczargiStareDom/KSD6.jpg';
import koczargiStareDom7 from '../assets/images/gallery/koczargiStareDom/KSD7.jpg';
import koczargiStareDom8 from '../assets/images/gallery/koczargiStareDom/KSD8.jpg';
import koczargiStareDom9 from '../assets/images/gallery/koczargiStareDom/KSD9.jpg';
import koczargiStareDom10 from '../assets/images/gallery/koczargiStareDom/KSD10.jpg';
import koczargiStareDom11 from '../assets/images/gallery/koczargiStareDom/KSD11.jpg';
import koczargiStareThumbnail from '../assets/images/gallery/koczargiStareDom/KSDthumbnail.jpg';

import klepaczeDom1 from '../assets/images/gallery/klepaczeDom/KD1.jpg';
import klepaczeDom2 from '../assets/images/gallery/klepaczeDom/KD2.jpg';
import klepaczeDom3 from '../assets/images/gallery/klepaczeDom/KD3.jpg';
import klepaczeDom4 from '../assets/images/gallery/klepaczeDom/KD4.jpg';
import klepaczeDom5 from '../assets/images/gallery/klepaczeDom/KD5.jpg';
import klepaczeDom6 from '../assets/images/gallery/klepaczeDom/KD6.jpg';
import klepaczeDom7 from '../assets/images/gallery/klepaczeDom/KD7.jpg';
import klepaczeDom8 from '../assets/images/gallery/klepaczeDom/KD8.jpg';
import klepaczeThumbnail from '../assets/images/gallery/klepaczeDom/KDthumbnail.jpg';

import poroslySegment1 from '../assets/images/gallery/poroslySegment/PS1.jpg';
import poroslySegment2 from '../assets/images/gallery/poroslySegment/PS2.jpg';
import poroslySegment3 from '../assets/images/gallery/poroslySegment/PS3.jpg';
import poroslySegment4 from '../assets/images/gallery/poroslySegment/PS4.jpg';
import poroslySegment5 from '../assets/images/gallery/poroslySegment/PS5.jpg';
import poroslySegment6 from '../assets/images/gallery/poroslySegment/PS6.jpg';
import poroslySegment7 from '../assets/images/gallery/poroslySegment/PS7.jpg';
import poroslyThumbnail from '../assets/images/gallery/poroslySegment/PSthumbnail.jpg';

import mlynowaMieszkanie1 from '../assets/images/gallery/mlynowaMieszkanie/MM1.jpg';
import mlynowaMieszkanie2 from '../assets/images/gallery/mlynowaMieszkanie/MM2.jpg';
import mlynowaMieszkanie3 from '../assets/images/gallery/mlynowaMieszkanie/MM3.jpg';
import mlynowaMieszkanie4 from '../assets/images/gallery/mlynowaMieszkanie/MM4.jpg';
import mlynowaMieszkanie5 from '../assets/images/gallery/mlynowaMieszkanie/MM5.jpg';
import mlynowaMieszkanie6 from '../assets/images/gallery/mlynowaMieszkanie/MM6.jpg';
import mlynowaMieszkanie7 from '../assets/images/gallery/mlynowaMieszkanie/MM7.jpg';
import mlynowaMieszkanie8 from '../assets/images/gallery/mlynowaMieszkanie/MM8.jpg';
import mlynowaMieszkanie9 from '../assets/images/gallery/mlynowaMieszkanie/MM9.jpg';
import mlynowaMieszkanie10 from '../assets/images/gallery/mlynowaMieszkanie/MM10.jpg';
import mlynowaMieszkanie11 from '../assets/images/gallery/mlynowaMieszkanie/MM11.jpg';
import mlynowaMieszkanie12 from '../assets/images/gallery/mlynowaMieszkanie/MM12.jpg';
import mlynowaMieszkanie13 from '../assets/images/gallery/mlynowaMieszkanie/MM13.jpg';
import mlynowaMieszkanie14 from '../assets/images/gallery/mlynowaMieszkanie/MM14.jpg';
import mlynowaMieszkanie15 from '../assets/images/gallery/mlynowaMieszkanie/MM15.jpg';
import mlynowaMieszkanie16 from '../assets/images/gallery/mlynowaMieszkanie/MM16.jpg';
import mlynowaMieszkanie17 from '../assets/images/gallery/mlynowaMieszkanie/MM17.jpg';
import mlynowaMieszkanie18 from '../assets/images/gallery/mlynowaMieszkanie/MM18.jpg';
import mlynowaMieszkanie19 from '../assets/images/gallery/mlynowaMieszkanie/MM19.jpg';
import mlynowaThumbnail from '../assets/images/gallery/mlynowaMieszkanie/MMthumbnail.jpg';

import kopernikaMieszkanie1 from '../assets/images/gallery/kopernikaMieszkanie/KM1.jpg';
import kopernikaMieszkanie2 from '../assets/images/gallery/kopernikaMieszkanie/KM2.jpg';
import kopernikaMieszkanie3 from '../assets/images/gallery/kopernikaMieszkanie/KM3.jpg';
import kopernikaMieszkanie4 from '../assets/images/gallery/kopernikaMieszkanie/KM4.jpg';
import kopernikaMieszkanie5 from '../assets/images/gallery/kopernikaMieszkanie/KM5.jpg';
import kopernikaMieszkanie6 from '../assets/images/gallery/kopernikaMieszkanie/KM6.jpg';
import kopernikaMieszkanie7 from '../assets/images/gallery/kopernikaMieszkanie/KM7.jpg';
import kopernikaMieszkanie8 from '../assets/images/gallery/kopernikaMieszkanie/KM8.jpg';
import kopernikaMieszkanie9 from '../assets/images/gallery/kopernikaMieszkanie/KM9.jpg';
import kopernikaMieszkanie10 from '../assets/images/gallery/kopernikaMieszkanie/KM10.jpg';
import kopernikaMieszkanie11 from '../assets/images/gallery/kopernikaMieszkanie/KM11.jpg';
import kopernikaMieszkanie12 from '../assets/images/gallery/kopernikaMieszkanie/KM12.jpg';
import kopernikaMieszkanie13 from '../assets/images/gallery/kopernikaMieszkanie/KM13.jpg';
import kopernikaMieszkanie14 from '../assets/images/gallery/kopernikaMieszkanie/KM14.jpg';
import kopernikaMieszkanie15 from '../assets/images/gallery/kopernikaMieszkanie/KM15.jpg';
import kopernikaThumbnail from '../assets/images/gallery/kopernikaMieszkanie/KMthumbnail.jpg';

import brzoskwiniowaDom1 from '../assets/images/gallery/brzoskwiniowaDom/BD1.jpg';
import brzoskwiniowaDom2 from '../assets/images/gallery/brzoskwiniowaDom/BD2.jpg';
import brzoskwiniowaDom3 from '../assets/images/gallery/brzoskwiniowaDom/BD3.jpg';
import brzoskwiniowaDom4 from '../assets/images/gallery/brzoskwiniowaDom/BD4.jpg';
import brzoskwiniowaDom5 from '../assets/images/gallery/brzoskwiniowaDom/BD5.jpg';
import brzoskwiniowaDom6 from '../assets/images/gallery/brzoskwiniowaDom/BD6.jpg';
import brzoskwiniowaDom7 from '../assets/images/gallery/brzoskwiniowaDom/BD7.jpg';
import brzoskwiniowaDom8 from '../assets/images/gallery/brzoskwiniowaDom/BD8.jpg';
import brzoskwiniowaDom9 from '../assets/images/gallery/brzoskwiniowaDom/BD9.jpg';
import brzoskwiniowaDom10 from '../assets/images/gallery/brzoskwiniowaDom/BD10.jpg';
import brzoskwiniowaDom11 from '../assets/images/gallery/brzoskwiniowaDom/BD11.jpg';
import brzoskwiniowaDom12 from '../assets/images/gallery/brzoskwiniowaDom/BD12.jpg';
import brzoskwiniowaDom13 from '../assets/images/gallery/brzoskwiniowaDom/BD13.jpg';
import brzoskwiniowaDom14 from '../assets/images/gallery/brzoskwiniowaDom/BD14.jpg';
import brzoskwiniowaDom15 from '../assets/images/gallery/brzoskwiniowaDom/BD15.jpg';
import brzoskwiniowaDom16 from '../assets/images/gallery/brzoskwiniowaDom/BD16.jpg';
import brzoskwiniowaDom17 from '../assets/images/gallery/brzoskwiniowaDom/BD17.jpg';
import brzoskwiniowaDom18 from '../assets/images/gallery/brzoskwiniowaDom/BD18.jpg';
import brzoskwiniowaDom19 from '../assets/images/gallery/brzoskwiniowaDom/BD19.jpg';
import brzoskwiniowaDom20 from '../assets/images/gallery/brzoskwiniowaDom/BD20.jpg';
import brzoskwiniowaThumbnail from '../assets/images/gallery/brzoskwiniowaDom/BDthumbnail.jpg';

import marczukowskaSegment1 from '../assets/images/gallery/marczukowskaSegment/MS1.jpg';
import marczukowskaSegment2 from '../assets/images/gallery/marczukowskaSegment/MS2.jpg';
import marczukowskaSegment3 from '../assets/images/gallery/marczukowskaSegment/MS3.jpg';
import marczukowskaSegment4 from '../assets/images/gallery/marczukowskaSegment/MS4.jpg';
import marczukowskaSegment5 from '../assets/images/gallery/marczukowskaSegment/MS5.jpg';
import marczukowskaSegment7 from '../assets/images/gallery/marczukowskaSegment/MS7.jpg';
import marczukowskaSegment8 from '../assets/images/gallery/marczukowskaSegment/MS8.jpg';
import marczukowskaSegment9 from '../assets/images/gallery/marczukowskaSegment/MS9.jpg';
import marczukowskaSegment10 from '../assets/images/gallery/marczukowskaSegment/MS10.jpg';
import marczukowskaSegment11 from '../assets/images/gallery/marczukowskaSegment/MS11.jpg';
import marczukowskaSegment12 from '../assets/images/gallery/marczukowskaSegment/MS12.jpg';
import marczukowskaSegment13 from '../assets/images/gallery/marczukowskaSegment/MS13.jpg';
import marczukowskaSegment14 from '../assets/images/gallery/marczukowskaSegment/MS14.jpg';
import marczukowskaSegment15 from '../assets/images/gallery/marczukowskaSegment/MS15.jpg';
import marczukowskaSegment16 from '../assets/images/gallery/marczukowskaSegment/MS16.jpg';
import marczukowskaSegment17 from '../assets/images/gallery/marczukowskaSegment/MS17.jpg';
import marczukowskaSegment18 from '../assets/images/gallery/marczukowskaSegment/MS18.jpg';
import marczukowskaSegment19 from '../assets/images/gallery/marczukowskaSegment/MS19.jpg';
import marczukowskaSegment20 from '../assets/images/gallery/marczukowskaSegment/MS20.jpg';
import marczukowskaSegment21 from '../assets/images/gallery/marczukowskaSegment/MS21.jpg';
import marczukowskaThumbnail from '../assets/images/gallery/marczukowskaSegment/MSthumbnail.jpg';

import michalowoDom1 from '../assets/images/gallery/michalowoDom/MD1.jpg';
import michalowoDom2 from '../assets/images/gallery/michalowoDom/MD2.jpg';
import michalowoDom3 from '../assets/images/gallery/michalowoDom/MD3.jpg';
import michalowoDom4 from '../assets/images/gallery/michalowoDom/MD4.jpg';
import michalowoDom5 from '../assets/images/gallery/michalowoDom/MD5.jpg';
import michalowoThumbnail from '../assets/images/gallery/michalowoDom/MDthumbnail.jpg';

import grabowkaSegment1 from '../assets/images/gallery/grabowkaSegment/GS1.jpg';
import grabowkaSegment2 from '../assets/images/gallery/grabowkaSegment/GS2.jpg';
import grabowkaSegment3 from '../assets/images/gallery/grabowkaSegment/GS3.jpg';
import grabowkaSegment4 from '../assets/images/gallery/grabowkaSegment/GS4.jpg';
import grabowkaSegment5 from '../assets/images/gallery/grabowkaSegment/GS5.jpg';
import grabowkaSegment6 from '../assets/images/gallery/grabowkaSegment/GS6.jpg';
import grabowkaSegment7 from '../assets/images/gallery/grabowkaSegment/GS7.jpg';
import grabowkaSegment8 from '../assets/images/gallery/grabowkaSegment/GS8.jpg';
import grabowkaSegment9 from '../assets/images/gallery/grabowkaSegment/GS9.jpg';
import grabowkaSegment10 from '../assets/images/gallery/grabowkaSegment/GS10.jpg';
import grabowkaSegment11 from '../assets/images/gallery/grabowkaSegment/GS11.jpg';
import grabowkaSegment12 from '../assets/images/gallery/grabowkaSegment/GS12.jpg';
import grabowkaSegment13 from '../assets/images/gallery/grabowkaSegment/GS13.jpg';
import grabowkaSegment14 from '../assets/images/gallery/grabowkaSegment/GS14.jpg';
import grabowkaSegment15 from '../assets/images/gallery/grabowkaSegment/GS15.jpg';
import grabowkaThumbnail from '../assets/images/gallery/grabowkaSegment/GSthumbnail.jpg';

import jurowieckaMieszkanie1 from '../assets/images/gallery/jurowieckaMieszkanie/JM1.jpg';
import jurowieckaMieszkanie2 from '../assets/images/gallery/jurowieckaMieszkanie/JM2.jpg';
import jurowieckaMieszkanie3 from '../assets/images/gallery/jurowieckaMieszkanie/JM3.jpg';
import jurowieckaMieszkanie4 from '../assets/images/gallery/jurowieckaMieszkanie/JM4.jpg';
import jurowieckaMieszkanie5 from '../assets/images/gallery/jurowieckaMieszkanie/JM5.jpg';
import jurowieckaMieszkanie6 from '../assets/images/gallery/jurowieckaMieszkanie/JM6.jpg';
import jurowieckaThumbnail from '../assets/images/gallery/jurowieckaMieszkanie/JMthumbnail.jpg';

import rozne1 from '../assets/images/gallery/rozne/J1.jpg';
import rozne2 from '../assets/images/gallery/rozne/K_1.jpg';
import rozne3 from '../assets/images/gallery/rozne/K_2.jpg';
import rozne4 from '../assets/images/gallery/rozne/K_4.jpg';
import rozne5 from '../assets/images/gallery/rozne/S1.jpg';
import rozne6 from '../assets/images/gallery/rozne/S3.jpg';
import rozne7 from '../assets/images/gallery/rozne/L1.jpg';
import rozne8 from '../assets/images/gallery/rozne/L2_1.jpg';
import rozne9 from '../assets/images/gallery/rozne/L2_3.jpg';
import rozne10 from '../assets/images/gallery/rozne/L2_4.jpg';
import rozne11 from '../assets/images/gallery/rozne/L3_1.jpg';
import rozne12 from '../assets/images/gallery/rozne/L3_3.jpg';
import rozneThumbnail from '../assets/images/gallery/rozne/Rthumbnail.jpg';

export const KK_STUDIO_PROJECTS = [
    { 
        name: 'wilno-mieszkanie',
        thumbnailImage: wilnoThumbnail,
        title: 'WILNO - MIESZKANIE',
        description: `
            Nowoczesne, minimalistyczne mieszkanie aktywnej rodziny - jasna, naturalna kolorystyka z wysokiej jakości materiałów,
            zaplanowany i zorganizowany każdy metr kwadratowy mieszkania niedaleko centrum Wilna - tak, tak,
            zagraniczne projekty też prowadzimy 😊 biel, drewno, piękne, ryflowane, drewniane fronty powtarzające się w kuchni
            i łazience. Co było wyzwaniem i czymś nowym - inne oznaczenia w projektach przyłączy elektrycznych, hydraulicznych,
            wszystkie ściany działowe w zabudowie lekkiej z g-k i kilka innych różnic do budownictwa polskiego. Sesja zdjęciowa 
            jeszcze przed nami. Realizacja – zima/wiosna 2022.
        `,
        flatArea: 79,
        gallery: [
            {
                original: wilnoMieszkanie1,
                thumbnail: wilnoMieszkanie1,
            },
            {
                original: wilnoMieszkanie2,
                thumbnail: wilnoMieszkanie2,
            },
            {
                original: wilnoMieszkanie3,
                thumbnail: wilnoMieszkanie3,
            },
            {
                original: wilnoMieszkanie4,
                thumbnail: wilnoMieszkanie4,
            },
            {
                original: wilnoMieszkanie5,
                thumbnail: wilnoMieszkanie5,
            },
            {
                original: wilnoMieszkanie6,
                thumbnail: wilnoMieszkanie6,
            },
            {
                original: wilnoMieszkanie7,
                thumbnail: wilnoMieszkanie7,
            },
            {
                original: wilnoMieszkanie8,
                thumbnail: wilnoMieszkanie8,
            }
        ],
        cooperation: null,
        visualizations: null,
        photos: null,
        paintings: 'Muural'
    },
    {
        name: 'serock-dom',
        thumbnailImage: serockThumbnail,
        title: 'SEROCK - DOM',
        description: `
            Dom położony w pięknej okolicy, wśród drzew i lasów – wśród głównych założeń ma być to miejsce do odpoczynku i relaksu
            po pracy oraz aktywnego życia towarzyskiego. Cały dom scala jasna kolorystyka, biel, odcienie beżu, drewno, beton, kamień, dużo roślin - wszystko miało
            współgrać z otoczeniem wokół domu. Ważną rolę w domu odgrywa oświetlenie – z racji wysokiej przestrzeni, widocznych jętek, należało
            specjalistycznie dobrać oprawy i moc światła. Mnóstwo nastrojowego oświetlenia nada jeszcze większego klimatu temu miejscu.
            Ogromne znaczenie w tym wnętrzu ma też układ funkcjonalny, strefowość, wyraźnie odznaczona kuchnia, a jednocześnie połączona z jadalnią i salonem, dzięki czemu
            przygotowując posiłki można być razem z bliskimi. Na parterze znajdują się jeszcze dwie sypialnie inwestorów i łazienka.
            Na antresoli, do której prowadzą kręcone schody z prefabrykatów, mamy kącik do czytania, łazienkę i dodatkowe dwa pokoje.
        `,
        flatArea: 164,
        gallery: [
            {
                original: serockDom1,
                thumbnail: serockDom1,
            },
            {
                original: serockDom2,
                thumbnail: serockDom2,
            },
            {
                original: serockDom3,
                thumbnail: serockDom3,
            },
            {
                original: serockDom4,
                thumbnail: serockDom4,
            },
            {
                original: serockDom5,
                thumbnail: serockDom5,
            },
            {
                original: serockDom6,
                thumbnail: serockDom6,
            },
            {
                original: serockDom7,
                thumbnail: serockDom7,
            },
            {
                original: serockDom8,
                thumbnail: serockDom8,
            },
            {
                original: serockDom9,
                thumbnail: serockDom9,
            },
            {
                original: serockDom10,
                thumbnail: serockDom10,
            },
            {
                original: serockDom11,
                thumbnail: serockDom11,
            }
        ],
        cooperation: null,
        visualizations: 'Anna Krystkowicz',
        photos: null,
        paintings: null
    },
    {
        name: 'koczargi-stare-dom',
        thumbnailImage: koczargiStareThumbnail,
        title: 'KOCZARGI STARE - DOM',
        description: `
            Dom z lat 90tych - założeniem projektu było odświeżyć, dostosować przestrzeń do potrzeb młodej rodziny, zachowując
            jednocześnie elementy klasyczne, sentymentalne, takie jak książki, obrazy, drewniane belki na suficie.
            Szarości, beże, naturalne drewno oświetlenie dostosowane wg potrzeb i stref.
            Na podłodze płytki imitujące jodełkę, z racji, że wśród domowników są dwa psy. Wyeksponowane dzieła sztuki i biblioteczka z książkami na antresoli.
            Mnóstwo miejsca do przechowywania, dwie łazienki – z prysznicem oraz z wanną.
            W łazienkach powtarza się kolorystyka i motyw jodełki i drewna. 
        `,
        flatArea: 120,
        gallery: [
            {
                original: koczargiStareDom1,
                thumbnail: koczargiStareDom1,
            },
            {
                original: koczargiStareDom2,
                thumbnail: koczargiStareDom2,
            },
            {
                original: koczargiStareDom3,
                thumbnail: koczargiStareDom3,
            },
            {
                original: koczargiStareDom4,
                thumbnail: koczargiStareDom4,
            },
            {
                original: koczargiStareDom5,
                thumbnail: koczargiStareDom5,
            },
            {
                original: koczargiStareDom6,
                thumbnail: koczargiStareDom6,
            },
            {
                original: koczargiStareDom7,
                thumbnail: koczargiStareDom7,
            },
            {
                original: koczargiStareDom8,
                thumbnail: koczargiStareDom8,
            },
            {
                original: koczargiStareDom9,
                thumbnail: koczargiStareDom9,
            },
            {
                original: koczargiStareDom10,
                thumbnail: koczargiStareDom10,
            },
            {
                original: koczargiStareDom11,
                thumbnail: koczargiStareDom11,
            }
        ],
        cooperation: 'Katarzyna Choińska',
        visualizations: null,
        photos: null,
        paintings: null
    },
    {
        name: 'klepacze-dom',
        thumbnailImage: klepaczeThumbnail,
        title: 'KLEPACZE - DOM',
        description: `
            Minimalistyczny dom w męskim stylu - drewno, beton, czerń, grafit. Wyraźny podział na strefy - z wiatrołapu przechodzimy do
            salonu – strefy wypoczynkowej  z  oświetleniem technicznym Labra lighting, na środku schody na piętro - do strefy sypialnianej - po lewej stronie kuchnia
            z jadalnią - z zachowaną kolorystyką. Czarne, mocne kolory dodają charakteru, miękkie tapicerowania i zasłony ocieplają przestrzeń.
            Całość łączą drewniane, dywanowe schody. 
        `,
        flatArea: 70,
        gallery: [
            {
                original: klepaczeDom1,
                thumbnail: klepaczeDom1,
            },
            {
                original: klepaczeDom2,
                thumbnail: klepaczeDom2,
            },
            {
                original: klepaczeDom3,
                thumbnail: klepaczeDom3,
            },
            {
                original: klepaczeDom4,
                thumbnail: klepaczeDom4,
            },
            {
                original: klepaczeDom5,
                thumbnail: klepaczeDom5,
            },
            {
                original: klepaczeDom6,
                thumbnail: klepaczeDom6,
            },
            {
                original: klepaczeDom7,
                thumbnail: klepaczeDom7,
            },
            {
                original: klepaczeDom8,
                thumbnail: klepaczeDom8,
            },
        ],
        cooperation: null,
        visualizations: null,
        photos: null,
        paintings: 'Desenio, Muural'
    },
    {
        name: 'porosly-segment',
        thumbnailImage: poroslyThumbnail,
        title: 'POROSŁY - SEGMENT',
        description: `
            Tym razem kobieca przestrzeń - szeregówka o niewielkim metrażu z wyraźnie zaznaczonymi strefami - optycznie oddzielona
            kuchnia od jadalni i salonu, oraz toaleta i wiatrołap na parterze - na piętrze dwa pokoje i łazienka. Drewno, biel i zgaszony
            róż skontrastowany z kamieniem i czernią mebli. Ultra kobiece wnętrze z charakterem. Minimalistyczne schody nadają tła całości.
        `,
        flatArea: 70,
        gallery: [
            {
                original: poroslySegment1,
                thumbnail: poroslySegment1,
            },
            {
                original: poroslySegment2,
                thumbnail: poroslySegment2,
            },
            {
                original: poroslySegment3,
                thumbnail: poroslySegment3,
            },
            {
                original: poroslySegment4,
                thumbnail: poroslySegment4,
            },
            {
                original: poroslySegment5,
                thumbnail: poroslySegment5,
            },
            {
                original: poroslySegment6,
                thumbnail: poroslySegment6,
            },
            {
                original: poroslySegment7,
                thumbnail: poroslySegment7,
            },
        ],
        cooperation: null,
        visualizations: null,
        photos: null,
        paintings: 'Desenio, Muural'
    },
    {
        name: 'mlynowa-mieszkanie',
        thumbnailImage: mlynowaThumbnail,
        title: 'MŁYNOWA - MIESZKANIE',
        description: `
            Mieszkanie w centrum Białegostoku, z zagospodarowanym każdym centymetrem przestrzeni - biel, przyszarzone panele podłogowe o wyraźnej
            fakturze i miękkie tkaniny - klasyczny minimalizm w najlepszym wydaniu.
            Wysokiej jakości materiały nadają elegancji i ponadczasowości wnętrza.
            Czy to dla singla, pary czy rodziny, na niewielkiej przestrzeni stworzyliśmy przytulną i funkcjonalną przestrzeń. 
        `,
        flatArea: 55,
        gallery: [
            {
                original: mlynowaMieszkanie1,
                thumbnail: mlynowaMieszkanie1,
            },
            {
                original: mlynowaMieszkanie2,
                thumbnail: mlynowaMieszkanie2,
            },
            {
                original: mlynowaMieszkanie3,
                thumbnail: mlynowaMieszkanie3,
            },
            {
                original: mlynowaMieszkanie4,
                thumbnail: mlynowaMieszkanie4,
            },
            {
                original: mlynowaMieszkanie5,
                thumbnail: mlynowaMieszkanie5,
            },
            {
                original: mlynowaMieszkanie6,
                thumbnail: mlynowaMieszkanie6,
            },
            {
                original: mlynowaMieszkanie7,
                thumbnail: mlynowaMieszkanie7,
            },
            {
                original: mlynowaMieszkanie8,
                thumbnail: mlynowaMieszkanie8,
            },
            {
                original: mlynowaMieszkanie9,
                thumbnail: mlynowaMieszkanie9,
            },
            {
                original: mlynowaMieszkanie10,
                thumbnail: mlynowaMieszkanie10,
            },
            {
                original: mlynowaMieszkanie11,
                thumbnail: mlynowaMieszkanie11,
            },
            {
                original: mlynowaMieszkanie12,
                thumbnail: mlynowaMieszkanie12,
            },
            {
                original: mlynowaMieszkanie13,
                thumbnail: mlynowaMieszkanie13,
            },
            {
                original: mlynowaMieszkanie14,
                thumbnail: mlynowaMieszkanie14,
            },
            {
                original: mlynowaMieszkanie15,
                thumbnail: mlynowaMieszkanie15,
            },
            {
                original: mlynowaMieszkanie16,
                thumbnail: mlynowaMieszkanie16,
            },
            {
                original: mlynowaMieszkanie17,
                thumbnail: mlynowaMieszkanie17,
            },
            {
                original: mlynowaMieszkanie18,
                thumbnail: mlynowaMieszkanie18,
            },
            {
                original: mlynowaMieszkanie19,
                thumbnail: mlynowaMieszkanie19,
            },
        ],
        cooperation: null,
        visualizations: null,
        photos: null,
        paintings: null
    },
    {
        name: 'kopernika-mieszkanie',
        thumbnailImage: kopernikaThumbnail,
        title: 'KOPERNIKA - MIESZKANIE',
        description: `
            To kolejne już męskie mieszkanie przekształcone drobnymi dodatkami w rodzinne gniazdko.
            Biele, szarości, beton ocieplają wysokiej jakości tkaniny w głębokich kolorach, blaty z konglomeratu kwarcowego
            oraz drewniane panele i dodatki z naturalnego drewna - zabudowa na książki - projekt autorski.
            Sypialnia idealna do wypoczynku, z piękną tapetą Wonderwall studio, głęboko granatowymi tkaninami na zasłony i drewnianymi żaluzjami,
            zastosowanymi w całym mieszkaniu. Dwie łazienki ułatwiają codzienne funkcjonowanie. 
        `,
        flatArea: 83,
        gallery: [
            {
                original: kopernikaMieszkanie1,
                thumbnail: kopernikaMieszkanie1,
            },
            {
                original: kopernikaMieszkanie2,
                thumbnail: kopernikaMieszkanie2,
            },
            {
                original: kopernikaMieszkanie3,
                thumbnail: kopernikaMieszkanie3,
            },
            {
                original: kopernikaMieszkanie4,
                thumbnail: kopernikaMieszkanie4,
            },
            {
                original: kopernikaMieszkanie5,
                thumbnail: kopernikaMieszkanie5,
            },
            {
                original: kopernikaMieszkanie6,
                thumbnail: kopernikaMieszkanie6,
            },
            {
                original: kopernikaMieszkanie7,
                thumbnail: kopernikaMieszkanie7,
            },
            {
                original: kopernikaMieszkanie8,
                thumbnail: kopernikaMieszkanie8,
            },
            {
                original: kopernikaMieszkanie9,
                thumbnail: kopernikaMieszkanie9,
            },
            {
                original: kopernikaMieszkanie10,
                thumbnail: kopernikaMieszkanie10,
            },
            {
                original: kopernikaMieszkanie11,
                thumbnail: kopernikaMieszkanie11,
            },
            {
                original: kopernikaMieszkanie12,
                thumbnail: kopernikaMieszkanie12,
            },
            {
                original: kopernikaMieszkanie13,
                thumbnail: kopernikaMieszkanie13,
            },
            {
                original: kopernikaMieszkanie14,
                thumbnail: kopernikaMieszkanie14,
            },
            {
                original: kopernikaMieszkanie15,
                thumbnail: kopernikaMieszkanie15,
            },
        ],
        cooperation: null,
        visualizations: null,
        photos: null,
        paintings: null
    },
    {
        name: 'brzoskwiniowa-dom',
        thumbnailImage: brzoskwiniowaThumbnail,
        title: 'BRZOSKWINIOWA - DOM',
        description: `
            Dom pełen energii, kolorów dla 5-osobowej rodziny - dzieje się dużo, jest kolorowo i odporne materiały wysokiej jakości – dużo
            tapet winylowych - Wonderwall Studio oraz Arte – strukturalnych, nadających głębi.
            Duża otwarta kuchnia z jadalnią i salonem – podzielone strefowo - jasna i ciemna + odważne kolory mebli,
            metalowe elementy tworzą całość bardzo przytulną i energetyczną.
            Uwielbiam to wnętrze! Oddaje w pełni energię domowników 😊 łazienka na parterze spójna z częścią dzienną.
            W miejscu garażu został zaprojektowany gabinet i „pokój teściowej” 😉 – z pełną sympatią każdej teściowej 😉 sypialnie oraz łazienka na piętrze stonowane,
            pastelowe - dla uspokojenia/ukojenia  i wyciszenia mieszkańców. Piękne dębowe schody z sękami o konstrukcji dywanowej,
            połączone z bezpiecznymi, specjalnie zaprojektowanymi pod małe dzieci barierkami i przegrodami. 
        `,
        flatArea: 153,
        gallery: [
            {
                original: brzoskwiniowaDom1,
                thumbnail: brzoskwiniowaDom1,
            },
            {
                original: brzoskwiniowaDom2,
                thumbnail: brzoskwiniowaDom2,
            },
            {
                original: brzoskwiniowaDom3,
                thumbnail: brzoskwiniowaDom3,
            },
            {
                original: brzoskwiniowaDom4,
                thumbnail: brzoskwiniowaDom4,
            },
            {
                original: brzoskwiniowaDom5,
                thumbnail: brzoskwiniowaDom5,
            },
            {
                original: brzoskwiniowaDom6,
                thumbnail: brzoskwiniowaDom6,
            },
            {
                original: brzoskwiniowaDom7,
                thumbnail: brzoskwiniowaDom7,
            },
            {
                original: brzoskwiniowaDom8,
                thumbnail: brzoskwiniowaDom8,
            },
            {
                original: brzoskwiniowaDom9,
                thumbnail: brzoskwiniowaDom9,
            },
            {
                original: brzoskwiniowaDom10,
                thumbnail: brzoskwiniowaDom10,
            },
            {
                original: brzoskwiniowaDom11,
                thumbnail: brzoskwiniowaDom11,
            },
            {
                original: brzoskwiniowaDom12,
                thumbnail: brzoskwiniowaDom12,
            },
            {
                original: brzoskwiniowaDom13,
                thumbnail: brzoskwiniowaDom13,
            },
            {
                original: brzoskwiniowaDom14,
                thumbnail: brzoskwiniowaDom14,
            },
            {
                original: brzoskwiniowaDom15,
                thumbnail: brzoskwiniowaDom15,
            },
            {
                original: brzoskwiniowaDom16,
                thumbnail: brzoskwiniowaDom16,
            },
            {
                original: brzoskwiniowaDom17,
                thumbnail: brzoskwiniowaDom17,
            },
            {
                original: brzoskwiniowaDom18,
                thumbnail: brzoskwiniowaDom18,
            },
            {
                original: brzoskwiniowaDom19,
                thumbnail: brzoskwiniowaDom19,
            },
            {
                original: brzoskwiniowaDom20,
                thumbnail: brzoskwiniowaDom20,
            },
        ],
        cooperation: null,
        visualizations: null,
        photos: null,
        paintings: null
    },
    {
        name: 'marczukowska-segment',
        thumbnailImage: marczukowskaThumbnail,
        title: 'MARCZUKOWSKA - SEGMENT',
        description: `
            Dom 4-osobowej rodziny, żyjącej aktywnie, energiczne małżeństwo z dwójką dzieci w różnym wieku. Realizacja – przełom 2018/2019r.
            Minimalistyczne, jasne wnętrze z ciemnymi akcentami - wytrzymałe materiały, proste w utrzymaniu czystości - wnętrza zaprojektowane tak,
            aby w prosty sposób, można było dodatkami zmienić wnętrze. Minimalistyczna baza zawsze jest ponadczasowym rozwiązaniem.
            W pokojach dziecięcych więcej „szaleństwa” – kolory i dużo miejsca na przechowywanie, przemyślane,
            aby wraz z rozwojem dzieci, pokoje nadal były praktyczne i rosły razem z dziećmi. Bielone drewno na podłogach i w dodatkach ociepla i tworzy ponadczasowy charakter wnętrz.  
        `,
        flatArea: 124,
        gallery: [
            {
                original: marczukowskaSegment1,
                thumbnail: marczukowskaSegment1,
            },
            {
                original: marczukowskaSegment2,
                thumbnail: marczukowskaSegment2,
            },
            {
                original: marczukowskaSegment3,
                thumbnail: marczukowskaSegment3,
            },
            {
                original: marczukowskaSegment4,
                thumbnail: marczukowskaSegment4,
            },
            {
                original: marczukowskaSegment5,
                thumbnail: marczukowskaSegment5,
            },
            {
                original: marczukowskaSegment7,
                thumbnail: marczukowskaSegment7,
            },
            {
                original: marczukowskaSegment8,
                thumbnail: marczukowskaSegment8,
            },
            {
                original: marczukowskaSegment9,
                thumbnail: marczukowskaSegment9,
            },
            {
                original: marczukowskaSegment10,
                thumbnail: marczukowskaSegment10,
            },
            {
                original: marczukowskaSegment11,
                thumbnail: marczukowskaSegment11,
            },
            {
                original: marczukowskaSegment12,
                thumbnail: marczukowskaSegment12,
            },
            {
                original: marczukowskaSegment13,
                thumbnail: marczukowskaSegment13,
            },
            {
                original: marczukowskaSegment14,
                thumbnail: marczukowskaSegment14,
            },
            {
                original: marczukowskaSegment15,
                thumbnail: marczukowskaSegment15,
            },
            {
                original: marczukowskaSegment16,
                thumbnail: marczukowskaSegment16,
            },
            {
                original: marczukowskaSegment17,
                thumbnail: marczukowskaSegment17,
            },
            {
                original: marczukowskaSegment18,
                thumbnail: marczukowskaSegment18,
            },
            {
                original: marczukowskaSegment19,
                thumbnail: marczukowskaSegment19,
            },
            {
                original: marczukowskaSegment20,
                thumbnail: marczukowskaSegment20,
            },
            {
                original: marczukowskaSegment21,
                thumbnail: marczukowskaSegment21,
            },
        ],
        cooperation: null,
        visualizations: null,
        photos: null,
        paintings: null
    },
    {
        name: 'michalowo-dom',
        thumbnailImage: michalowoThumbnail,
        title: 'MICHAŁOWO - DOM',
        description: `
            Dom jednorodzinny młodego małżeństwa z parterem, na którym znajduje się strefa dzienna, kuchnia, jadalnia, gabinet i łazienka,
            minimalistyczne wnętrze z elementami industrialnymi. Na piętro prowadzą schody na konstrukcji metalowej, z drewnianymi stopniami i oknem
            wysokim na dwa piętra. Na piętrze znajdują się sypialnie i łazienka. Wszystko w spójnej kolorystyce z energetycznymi bordowymi dodatkami.
            Dużo szaf, miejsca na przechowywanie. W centralnym miejscu domu minimalistyczny kominek ocieplający  i nadający przytulności w zimowe wieczory.
        `,
        flatArea: 116,
        gallery: [
            {
                original: michalowoDom1,
                thumbnail: michalowoDom1,
            },
            {
                original: michalowoDom2,
                thumbnail: michalowoDom2,
            },
            {
                original: michalowoDom3,
                thumbnail: michalowoDom3,
            },
            {
                original: michalowoDom4,
                thumbnail: michalowoDom4,
            },
            {
                original: michalowoDom5,
                thumbnail: michalowoDom5,
            },
        ],
        cooperation: null,
        visualizations: null,
        photos: null,
        paintings: null
    },
    {
        name: 'grabowka-segment',
        thumbnailImage: grabowkaThumbnail,
        title: 'GRABÓWKA - SEGMENT',
        description: `
            Segment na nowo wybudowanym osiedlu w Grabówce w okolicach Białegostoku.
            Projekt z nadzorem autorskim, realizacja II połowa 2018r. Wiele zabudów na wymiar, styl angielski, przytulny z akcentami
            skandynawskimi. Biele, szarości, sztukateria oraz meble tworzą spójną całość. Na parterze zaprojektowana została
            strefa gościnno-wypoczynkowa z kuchnią, salonem, jadalnią oraz mikro wc pod schodami i zabudową
            na przechowywanie. Piętro - część sypialniana - z pokojami dzieci, sypialnią z garderobą, pralnią
            i dużym salonem kąpielowym. Na 98m2 udało się zaplanować wszystkie potrzebne pomieszczenia i funkcje dla młodej rodziny.
            Dopełnieniem projektu są piękne klasyczne schody ze zdobnymi barierkami.
        `,
        flatArea: 98,
        gallery: [
            {
                original: grabowkaSegment1,
                thumbnail: grabowkaSegment1,
            },
            {
                original: grabowkaSegment2,
                thumbnail: grabowkaSegment2,
            },
            {
                original: grabowkaSegment3,
                thumbnail: grabowkaSegment3,
            },
            {
                original: grabowkaSegment4,
                thumbnail: grabowkaSegment4,
            },
            {
                original: grabowkaSegment5,
                thumbnail: grabowkaSegment5,
            },
            {
                original: grabowkaSegment6,
                thumbnail: grabowkaSegment6,
            },
            {
                original: grabowkaSegment7,
                thumbnail: grabowkaSegment7,
            },
            {
                original: grabowkaSegment8,
                thumbnail: grabowkaSegment8,
            },
            {
                original: grabowkaSegment9,
                thumbnail: grabowkaSegment9,
            },
            {
                original: grabowkaSegment10,
                thumbnail: grabowkaSegment10,
            },
            {
                original: grabowkaSegment11,
                thumbnail: grabowkaSegment11,
            },
            {
                original: grabowkaSegment12,
                thumbnail: grabowkaSegment12,
            },
            {
                original: grabowkaSegment13,
                thumbnail: grabowkaSegment13,
            },
            {
                original: grabowkaSegment14,
                thumbnail: grabowkaSegment14,
            },
            {
                original: grabowkaSegment15,
                thumbnail: grabowkaSegment15,
            },
        ],
        cooperation: null,
        visualizations: null,
        photos: null,
        paintings: null
    },
    {
        name: 'jurowiecka-mieszkanie',
        thumbnailImage: jurowieckaThumbnail,
        title: 'JUROWIECKA - MIESZKANIE',
        description: `
            Mieszkanie klasyczno-prowansalskie. Niewielki metraż - 35m2, jasne, z wieloma zabudowami i schowkami. Kolorystyka kremy,
            naturalnie bielone drewno, dodatki fioletowe,  białe, beżowe. Mnóstwo kobiecych dodatków oddaje charakter właścicielki.
            W kuchni szkło z fototapetą z wrzosami, które pięknie wypełniają spokojną całość.
        `,
        flatArea: 37,
        gallery: [
            {
                original: jurowieckaMieszkanie1,
                thumbnail: jurowieckaMieszkanie1,
            },
            {
                original: jurowieckaMieszkanie2,
                thumbnail: jurowieckaMieszkanie2,
            },
            {
                original: jurowieckaMieszkanie3,
                thumbnail: jurowieckaMieszkanie3,
            },
            {
                original: jurowieckaMieszkanie4,
                thumbnail: jurowieckaMieszkanie4,
            },
            {
                original: jurowieckaMieszkanie5,
                thumbnail: jurowieckaMieszkanie5,
            },
            {
                original: jurowieckaMieszkanie6,
                thumbnail: jurowieckaMieszkanie6,
            },
        ],
        cooperation: null,
        visualizations: null,
        photos: null,
        paintings: null
    },
    {
        name: 'rozne',
        thumbnailImage: rozneThumbnail,
        title: 'RÓŻNE',
        description: null,
        flatArea: null,
        gallery: [
            {
                original: rozne1,
                thumbnail: rozne1,
            },
            {
                original: rozne2,
                thumbnail: rozne2,
            },
            {
                original: rozne3,
                thumbnail: rozne3,
            },
            {
                original: rozne4,
                thumbnail: rozne4,
            },
            {
                original: rozne5,
                thumbnail: rozne5,
            },
            {
                original: rozne6,
                thumbnail: rozne6,
            },
            {
                original: rozne7,
                thumbnail: rozne7,
            },
            {
                original: rozne8,
                thumbnail: rozne8,
            },
            {
                original: rozne9,
                thumbnail: rozne9,
            },
            {
                original: rozne10,
                thumbnail: rozne10,
            },
            {
                original: rozne11,
                thumbnail: rozne11,
            },
            {
                original: rozne12,
                thumbnail: rozne12,
            },
        ],
        cooperation: null,
        visualizations: null,
        photos: null,
        paintings: null
    },
];

export const EMPTY_PROJECT ={ 
    name: '-1',
    thumbnailImage: '',
    title: '',
    description: '',
    flatArea: 0,
    gallery: [],
    inCooperation: false,
    cooperationCompany: '',
    visualizationsAuthors: '',
    photosAuthors: ''
};