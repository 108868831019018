import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import ModalComponent from '../modalComponent';

export const ModalWrapper = props => {         
    window.addEventListener('scroll', () => {
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });
    
    return (
        <CSSTransition
            classNames="modal"
            timeout={500}
            in={props.showModal}
            unmountOnExit
        >
            <ModalComponent
                closeModal={props.closeModal}
                className={props.className}
            >
                {props.children}
            </ModalComponent>
        </CSSTransition>
    );
};

ModalWrapper.propTypes = {
    children: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default ModalWrapper;