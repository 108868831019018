import styled from 'styled-components/macro';

export const Details = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 0;
  
  .full-side {
    width: 100%;
    overflow: hidden;
    padding: 0 10px;
    margin-bottom: 25px;

    @media (min-width: 768px) {
      margin-bottom: 0;
      padding: 0 20px;
    }

    &__wrapper {
      display: block;
    }
  }
  .left-side {
    width: 100%;
    overflow: hidden;
    padding: 0 10px;
    margin-bottom: 25px;

    @media (min-width: 768px) {
      width: calc(100% - 270px);
      margin-bottom: 0;
      padding: 0 20px;
    }

    @media (min-width: 1024px) {
      width: calc(100% - 370px);
    }
    
    @media (min-width: 1400px) {
      width: calc(100% - 440px);
    }

    &__wrapper {
      display: block;
    }
  }

  .right-side{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0 20px 0 15px;

    @media (min-width: 768px) {
      width: 270px;
      padding: 0 20px 0 15px;
    }

    @media (min-width: 1024px) {
      width: 370px;
      padding: 0 50px 0 30px;
    }
    @media (min-width: 1400px) {
      width: 440px;
    }

    .title-wrapper {
      align-self: center;

      &__title {
        font-size: 18px;
        line-height: 32px;
        margin: 0 0 15px;
        text-align: center;

        @media (min-width: 1024px) {
          font-size: 24px;
        }

        @media (min-width: 1400px) {   
          font-size: 28px;
        }
      }

      &__divider {
        display: block;
        width: 90%;
        height: 0px;
        margin: 0 auto;
        border-bottom: 1px solid #000000;
        margin-bottom: 5px;
      }
      
      &__area {
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        margin: 0 0 20px;

        @media (min-width: 1024px) {
          font-size: 15px;
        }

        @media (min-width: 1400px) {   
          font-size: 16px;
        }
      }
    }

    &__description {
      font-size: 14px;
      line-height: 24px;
      text-align: justify;
      margin: 0 0 45px;

      @media (min-width: 1024px) {   
        font-size: 16px;
      }

      @media (min-width: 1400px) {   
        font-size: 18px;
      }
    }

    &__authors {
      width: 100%;
      text-align: left;
      margin: 0 0 45px;

      p {
        font-size: 12px;
        line-height: 18px;
        margin: 0 0 5px;

        @media (min-width: 1024px) {   
          font-size: 14px;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    &__cooperation {
      font-size: 12px;;
      line-height: 18px;
      text-align: left;
      margin: 0;

      @media (min-width: 1024px) {   
        font-size: 14px;
      }

      span {
        text-decoration: underline;
      }
    }
  }
`;
