import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import loadingPageView from '../hoc/loadingPageView.js';

import { startLoadingPageData, stopLoadingPageData } from "../actions/actions";

import { getSliderAnimationTime, getSliderIntervalTime } from '../selectors/homePageDataSelectors.js';

import FullScreenImageSlider from '../components/fullScreenImageSlider';

import image0 from "../assets/images/slider/slider_0.jpg";
import image1 from "../assets/images/slider/slider_1.jpg";
import image2 from "../assets/images/slider/slider_2.jpg";
import image3 from "../assets/images/slider/slider_3.jpg";
import image4 from "../assets/images/slider/slider_4.jpg";
import image5 from "../assets/images/slider/slider_5.jpg";
import image6 from "../assets/images/slider/slider_6.jpg";
import image7 from "../assets/images/slider/slider_7.jpg";

class HomePage extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.stopLoadingPageData();
    },5000)
  }

  render() {
    const { sliderAnimationTime, sliderIntervalTime } = this.props;
    const sliderImages = [
      image0,
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
    ];
    
    return (
      <FullScreenImageSlider
          sliderImages={sliderImages}
          sliderAnimationTime={sliderAnimationTime}
          sliderIntervalTime={sliderIntervalTime}
      />
    );
  }
}

HomePage.propTypes = {
  sliderAnimationTime: PropTypes.number.isRequired,
  sliderIntervalTime: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  return {
    sliderAnimationTime: getSliderAnimationTime(state),
    sliderIntervalTime: getSliderIntervalTime(state)
  }
};

const mapDispatchToProps = {
  startLoadingPageData,
  stopLoadingPageData
};

export default loadingPageView(connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage));