import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import routes from './routes';
import HeaderMenu from "./components/headerMenuComponent";

export default class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <HeaderMenu />
        <main className="main">
          {routes}
        </main>
        <div id='modal-root'></div>
      </BrowserRouter>
    );
  }
}
