import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { EMPTY_PROJECT } from '../../constants/projects';
import { getProjectsList } from '../../selectors/projectsSelectors';
import ModalWrapper from '../../components/modalWrapper';
import GridGallery from '../../components/GridGallery';

import { Details } from "./styled-components";


class ProjectDetail extends React.PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDownCloseProject);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDownCloseProject);
  }
  onKeyDownCloseProject = event => {
    if(event.key === 'Escape'){
      this.props.closeModal();
    }
  };
  render() {
    const { title, flatArea, description, gallery, cooperation, visualizations, photos, paintings } = this.props.projectData;
    
    return (
      <ModalWrapper
        closeModal={this.props.closeModal}
        showModal={this.props.isShowingDetail}
        className="project-content"
      >
        <Details>
          <div className={description ? 'left-side' : 'full-side'}>
            <div className='left-side__wrapper'>
              <GridGallery images={gallery} />
            </div>
          </div>
          {description && (
            <div className='right-side'>
              <div className='title-wrapper'>
                <h2 className='title-wrapper__title'>{title}</h2>
                <div className='title-wrapper__divider' />
                <p className='title-wrapper__area'>{`${flatArea} m`}<sup>2</sup></p>
              </div>
              <p className='right-side__description'>{description}</p>
              <div className='right-side__authors'>
                {visualizations && <p>{`Wizualizacje: ${visualizations}`}</p>}
                {photos && <p>{`Foto: ${photos}`}</p>}
                {paintings && <p>{`Obrazy: ${paintings}`}</p>}
                {cooperation && <p>{`Współpraca projektowa: ${cooperation}`}</p>}
              </div>
            </div>
          )}
        </Details>
      </ModalWrapper>
    );
  };
}

const { bool, string, func, object } = PropTypes;

ProjectDetail.defaultProps = {
  showingProjectName: null
}

ProjectDetail.propTypes = {
  showingProjectName: string,
  projectData: object,
  isShowingDetail: bool.isRequired,
  closeModal: func.isRequired
};

export default connect(
  (state, props) => {
    const projectsList = getProjectsList(state);
    const projectData = projectsList.find(item => item.name === props.showingProjectName);

    return {
      projectData: projectData ? projectData : EMPTY_PROJECT
    }
  }
)(ProjectDetail);